import React, { useRef, useEffect, useState } from "react";
import "./Previous.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import AboutAHSEC from "./AboutAHSEC";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { useContext } from "react";

import axios from "axios";
import qs from "qs";
import AboutCBSE from "./AboutCBSE";
import AboutDibrugarh from "./AboutDibrugarh";
import AdsModalForInstitute from "../../components/Modals/AdsModalForInstitute";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import AboutParticularInstitute from "./AboutParticularInstitute";
// import { useRef } from "react";
// import AppContext from "../../feature/ContextForHeader/ContextForheader";
// import { useContext } from "react";

const Institute = () => {
  //React Hooks
  // const navigate = useNavigate();
  const { stateforheader } = useContext(AppContext);

  const location = useLocation();
  const [course, setCourse] = useState([]);
  const [coursenameheading, setCourseNameHeading] = useState();
  const [semester, setSemester] = useState([]);
  const [selectedcoursesemester, setSelectedCourseSemester] = useState([]);
  const [coursetypeselectedsemester, setCourseTypeSelectedSemester] = useState(
    []
  );
  const [semesterselected, setSelectedSemester] = useState();
  const [subjects, setSubjects] = useState([]);
  const [selectedcoursetype, setSelectedCourseType] = useState();
  const [active1, setActive1] = useState();
  const [active2, setActive2] = useState();
  const [activeitem2, setActiveItem2] = useState();
  const [modules, setModules] = useState();
  const [activeitem3, setActiveItem3] = useState();
  const [bookname, setBookName] = useState();
  const [pdfUrl, setPdfUrl] = useState("");
  const [module, setModule] = useState();
  const [selectedcourse, setSelectedCourse] = useState();
  const [showModal, setShowModal] = useState(false);
  const [adsvalue, setAdsVal] = useState();
  const [comesFrom, setComesFrom] = useState();
  const [renderingstate, setRenderingState] = useState(false);
  const [semestername, setSemesterName] = useState();
  const coursedata = useRef();
  const semsterdataclick = useRef();
  const [adscomesfrom, setAdsComesFrom] = useState();
  const [modulefordownload, setModuleForDownload] = useState();
  const [modulename, setModuleName] = useState();

  const iconstyle = {
    height: "25px",
    width: "25px",
  };
// console.log("location" , location)
// console.log("location?.state" , location?.state)
  useEffect(() => {
    getAllCources();
    window.scroll(0, 0);
    setSelectedCourseSemester("");
    setSubjects("");
    setActiveItem2("");
    setCourseNameHeading("");
    // getAdsValue();
  }, []);

  useEffect(() => {
    getAllCources();
    setCourseTypeSelectedSemester("");
    setSubjects("");
    setModules("");
    setActiveItem2("");
    setActive1("");
    setCourseNameHeading("");
    setSelectedCourseSemester("");
    window.scroll(0, 0);
  }, [stateforheader]);

  //Functions
  const handleModal = (ads, forpdf) => {
    setModuleForDownload(forpdf);
    setShowModal(!showModal);
    setAdsVal(ads);
  };

  const getAdsValue = async (comesfrom, forpdf) => {
    try {
      const res = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
      );
      if (res.status === 200) {
        const data = res.data.institute;
        Object.keys(data).map((key) => {
          if (key === comesfrom[0]) {
            handleModal(data[key], forpdf);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCources = async () => {
    const postdata = {
      institute: location.state.institute,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_courses.php`,
        qs.stringify(postdata),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        postdata.course = response.data[0].course_title;
        // setCourseNameHeading(response.data[0].course_title);
        setCourse(response.data);
        getSemesters(postdata);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemesters = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semesters.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setSemester(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSemesterCourseWise = async (courseselected) => {
    const data = {
      institute: location.state.institute,
      course: courseselected,
    };
    setSelectedCourse(courseselected);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semesters.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setSelectedCourseSemester(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourseType = async (semesterselected) => {
    const data = {
      institute: location.state.institute,
      course: coursenameheading,
      semester: semesterselected,
    };

    setSelectedSemester(semesterselected);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_coursetype.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setCourseTypeSelectedSemester(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSubjects = async (courseType) => {
    const data = {
      institute: location.state.institute,
      course: coursenameheading,
      semester: semesterselected,
      coursetype: courseType,
    };
    setSelectedCourseType(courseType);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_subjects.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        setSubjects(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getModules = async (bookName) => {
    const data = {
      institute: location.state.institute,
      course: coursenameheading,
      semester: semesterselected,
      coursetype: selectedcoursetype,
      subject: bookName,
    };
    setBookName(bookName);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_modules.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        console.log("res.data pdf:::::::::::::::::::", response.data);
        setModules(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{location.state.institute}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main 12">
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p className="py-5">Select Your Course Here :</p>
                  <div className="button_div">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {course && course?.length
                        ? course?.map((key, index) => {
                          
                            return (
                              <div
                                className="col-md-3 "
                                ref={coursedata}
                                key={index}
                              >
                                <button
                                  onClick={() => {
                                    setCourseNameHeading(key.course_title);
                                    getSemesterCourseWise(key.course_title);
                                    getAdsValue(Object.keys(key));

                                    setSemester("");
                                    setSubjects("");
                                    setCourseTypeSelectedSemester("");

                                    // scollToRef1.current.scrollIntoView();
                                    setActive1(key.course_title);
                                    setActive2("");
                                    setActiveItem2("");
                                  }}
                                  className={`btn btn-primary mx-2 ${
                                    active1 === key.course_title
                                      ? "activecourse"
                                      : ""
                                  }`}
                                >
                                  <div className="button-inner">
                                    {key.course_title}
                                  </div>
                                </button>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
                {/* <div className="divider" ref={scollToRef1}></div> */}
              </div>
            </div>
            <div className="row mt-5">
              <div className="first_year_acc text-center d-block">
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      {coursenameheading}
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq py-1 my-5">
          <div className="container">
            <div className="row my-4">
              {selectedcoursesemester && (
                <p className="heading-semester text-center">
                  Select Your Semester or Class Below:
                </p>
              )}
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav  nav-pills "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <>
                      {selectedcoursesemester &&
                        selectedcoursesemester?.map((semester, ind) => {
                          return (
                            <>
                              <button
                                key={ind}
                                className={`nav-link ${
                                  activeitem2 === semester.semester
                                    ? "active"
                                    : ""
                                }`}
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                                ref={semsterdataclick}
                                onClick={() => {
                                  // getCourseType(semester.semester);
                                  // getAdsValue(Object.keys(semester));
                                  // getAdsValue();
                                  // if (isRendered) {
                                  getCourseType(semester.semester);
                                  getAdsValue(Object.keys(semester));
                                  // }
                                  setSemesterName(semester.semester);
                                  setSemester("");
                                  setSubjects("");
                                  setActive2("");
                                  setPdfUrl("");
                                  setActiveItem2(semester.semester);
                                }}
                              >
                                <i className="fa-regular fa-folder me-3"></i>{" "}
                                {semester.semester}
                              </button>
                            </>
                          );
                        })}
                    </>
                  </div>

                  <div className="col-md-12">
                    {/* <div className="button_div"> */}
                    <div className="button_div p-4 ml-5 d-flex justify-content-center align-items-center">
                      <div className="row d-flex justify-content-center">
                        {selectedcoursesemester && (
                          <p className="heading-semester text-center">
                            Select Course Type:
                          </p>
                        )}
                        <div className="col-md-12">
                          <div className="d-flex justify-content-center mt-5 align-item-center">
                            <div>
                              {coursetypeselectedsemester &&
                                coursetypeselectedsemester?.length > 0 &&
                                coursetypeselectedsemester?.map((item, ind) => {
                                  if (item.course_type.length > 0) {
                                    return (
                                      <>
                                        <button
                                          key={ind}
                                          onClick={() => {
                                            getSubjects(item.course_type);
                                            setActive2(item.course_type);
                                            setPdfUrl("");
                                            getAdsValue(Object.keys(item));
                                          }}
                                          className={` btn btn-primary mx-2 ${
                                            active2 === item.course_type
                                              ? "activecoursetype"
                                              : ""
                                          }`}
                                        >
                                          <div className="button-inner">
                                            {item.course_type}
                                          </div>
                                        </button>
                                      </>
                                    );
                                  } else {
                                    return (
                                      <>
                                        <p>Updating...</p>
                                      </>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-md-12">
                      <div className="p-4 study-section">
                        {subjects &&
                          subjects?.length > 0 &&
                          subjects?.map((item, ind) => {
                            return (
                              <div
                                key={ind}
                                className="accordion accordion-module"
                                id={`accordion${ind}`}
                              >
                                <div className="accordion-item ">
                                  <h2
                                    className="accordion-header"
                                    id={`heading${ind}`}
                                  >
                                    <button
                                      className={`accordion-button study-accordion  w-100 d-flex justify-content-between`}
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={`#collapse${ind}`}
                                      aria-expanded={
                                        activeitem3 === item.subject_name
                                          ? "true"
                                          : "false"
                                      }
                                      onClick={() => {
                                        if (activeitem3 === item.subject_name) {
                                          setActiveItem3("");
                                        } else {
                                          setActiveItem3(item.subject_name);
                                        }
                                        getModules(item.subject_name);
                                        setPdfUrl("");
                                        getAdsValue(Object.keys(item));
                                      }}
                                    >
                                      <div>{item.subject_name}</div>{" "}
                                      {activeitem3 === item.subject_name ? (
                                        <>
                                          <AiOutlineMinus style={iconstyle} />
                                        </>
                                      ) : (
                                        <>
                                          <AiOutlinePlus style={iconstyle} />
                                        </>
                                      )}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapse${ind}`}
                                    className={`accordion-collapse collapse ${
                                      activeitem3 === item.subject_name
                                        ? "show"
                                        : ""
                                    }`}
                                    aria-labelledby={`heading${ind}`}
                                    data-bs-parent={`#accordion${ind}`}
                                  >
                                    <div className="card-body d-flex flex-wrap">
                                      {modules &&
                                        modules?.map((item, ind1) => {
                                          return (
                                            <div
                                              key={ind1}
                                              className="module-btn mx-3 my-2 btn "
                                              // style={{
                                              //   cursor: "pointer",
                                              //   backgroundColor: "#0ea1ff",
                                              //   border: "none",
                                              //   borderRadius: "5px",
                                              // }}
                                              onClick={() => {
                                                getAdsValue(
                                                  Object.keys(item),
                                                  "From-pdflink"
                                                );
                                                setModuleName(item.module_name);
                                              }}
                                            >
                                              {/* <p className="Module-Text px-2 py-1 "> */}
                                                {item.module_name}
                                              {/* </p> */}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showModal && (
          <AdsModalForInstitute
            show={showModal}
            state={
              modulefordownload?.length > 0
                ? {
                    institute: location.state.institute,
                    course: selectedcourse,
                    coursetype: selectedcoursetype,
                    bookname: bookname,
                    semester: semesterselected,
                    module: modulename,
                  }
                : ""
            }
            close={handleModal}
            adsval={adsvalue}
            fornavigate={modulefordownload}
            // renderingstate={renderingstate}
            // updateParentState={updateParentState}
          />
        )}

        <hr className="" />
      </div>
      <section className="my-4">
        {/* {console.log(location?.state ,location?.state?.InstituteId, "##")} */}
        {location?.state?.institute && location?.state?.InstituteId && <AboutParticularInstitute id={location?.state?.InstituteId}/>}
        
        {/* {location.state.institute === "AHSEC (Assam)" && <AboutAHSEC />}

        {location.state.institute === "CBSE" && <AboutCBSE />}
        {location.state.institute === "Dibrugarh University" && <AboutDibrugarh />} */}
      </section>
    </>
  );
};

export default Institute;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import "./Previous.css";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { AdsModalForPrevious } from "../../components/Modals/AdsModalForPrevious";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export const StudyMaterial1 = () => {
  const { stateforheader } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();
  // const [institutedata, setInstituteData] = useState();
  const [subjectname, setSubjectName] = useState();
  const [data, setData] = useState([]);
  const [semestername, setSemesterName] = useState();
  const [coursedata, setCourseData] = useState();
  const [semesters, setSemesters] = useState();
  // const [activeitem1, setactiveitem] = useState();
  const [activeitem2, setActiveItem2] = useState();
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [download, setDownload] = useState();
  const [modulename, setModuleName] = useState();
  const [activeitem3, setActiveItem3] = useState();
  const [adsVal, setAdsVal] = useState();
  const [showModal, setShowModal] = useState(false);
  const [downloadPdfLink, setDownloadPdfLink] = useState();
  const [pdfLink, setPdfLink] = useState();

  const iconstyle = {
    height: "25px",
    width: "25px",
  };

  useEffect(() => {
    setDownload("");
  }, [setBooks]);

  useEffect(() => {
    getStudyMaterial();
    adsFun();
    window.scroll(0, 0);
    setSubjects("");
    setActiveItem2("");
    setBooks("");
    setDownload("");
  }, []);

  useEffect(() => {
    // getInstitute();
    getStudyMaterial();
    setSemesters("");
    setSubjects("");
    setActiveItem2("");
    window.scroll(0, 0);
  }, [stateforheader]);

  const getStudyMaterial = async () => {
    const institute = location.state.institute.replace(/\s+/g, "_").trim();
    const modulename = location.state.study.replace(/\s+/g, "_");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/new/Study_Materials.php?institute=${institute}&module=${modulename}`,

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.status === 200) {
        setData(res.data.data.semster);
        const data = res.data.data.semster;
        const filteredSemesters = Object.fromEntries(
          Object.entries(data).filter(([_, arr]) => arr.length !== 0)
        );
        let Semester = Object.keys(filteredSemesters).map((key) => {
          return key;
        });
        setSemesters(Semester);
        setCourseData(res.data.data.course);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSubjects = (semester) => {
    for (const key in data) {
      if (key === semester) {
        let arr = [];
        arr.push(data[key]);
        setSubjects(arr);
      }
    }
  };

  const getBooks = (book) => {
    subjects[0].map((item) => {
      if (item.subject_name === book) {
        for (let key in item) {
          setBooks(item[key]);
        }
      }
    });
  };

  const adsFun = async (comesfrom, forpdf) => {
    const response = await axios.get(
      "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
    );

    if (response.status === 200) {
      let data = response.data.menu.PreviousQuestionPerpres;

      Object.keys(data).forEach((key) => {
        if (key === comesfrom) {
          handleModal(data[key], forpdf);
        }
      });
    }
  };
  const handleModal = (ads, forpdf) => {
    setAdsVal(ads);
    setShowModal(!showModal);
    setDownloadPdfLink(forpdf);
  };

  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{location?.state?.institute}</h1>
                <p>{location?.state?.study}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <AdsModalForPrevious
          show={showModal}
          handleModal={handleModal}
          adsval={adsVal}
          fornavigate={downloadPdfLink}
          state={
            downloadPdfLink
              ? {
                  institute: location?.state?.institute,
                  studymaterialname: location.state.study,
                  modulepdf: pdfLink,
                  semestername: semestername,
                  subject: subjectname,
                  course: coursedata,
                }
              : ""
          }
          close={handleModal}
        />
      )}

      <div className="previous-question Faq py-1 my-1">
        {coursedata && <h3 className="text-center py-5">{coursedata}</h3>}
        <div className="container">
          <div className="row">
            <div className="previous_pdf previous_one tabs_button d-flex justify-content-center">
              <div className="col-md-12">
                {semesters?.length > 0 && (
                  <p className="Select-semsester-text text-center py-5">
                    Select Your Semester Here
                  </p>
                )}
                <div
                  className="nav  nav-pills "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {semesters &&
                    semesters?.map((item, ind) => {
                      return (
                        <button
                          key={ind}
                          onClick={() => {
                            getSubjects(item);
                            adsFun("semester");
                            setActiveItem2(item);
                            setBooks("");
                            setDownload("");
                            setActiveItem3("");
                            setSemesterName(item);
                          }}
                          className={`nav-link ${
                            activeitem2 === item ? "active" : ""
                          }`}
                          id={`v-pills-${ind}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#v-pills-${ind}`}
                          type="button"
                          role="tab"
                          aria-controls={`v-pills-${ind}`}
                          aria-selected={
                            activeitem2 === item ? "true" : "false"
                          }
                        >
                          <i className="fa-regular fa-folder me-3"></i> {item}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="py-4 text-center">
                {subjects[0] && (
                  <p className="Download-module-text">
                    Please select a Subject and Then Year to Download Module
                  </p>
                )}
              </div>

              <div className="row">
                <div className="col py-4">
                  {subjects[0] &&
                    subjects[0]?.map((item, ind) => (
                      <div
                        key={ind}
                        className="accordion accordion-position"
                        id={`accordion${ind}`}
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`heading${ind}`}>
                            <button
                              className={`accordion-button study-accordion w-100 d-flex justify-content-between ${
                                activeitem3 === item.subject_name
                                  ? ""
                                  : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${ind}`}
                              aria-expanded={
                                activeitem3 === item.subject_name
                                  ? "true"
                                  : "false"
                              }
                              onClick={() => {
                                adsFun("subject_name");
                                getBooks(item.subject_name);

                                if (activeitem3 === item.subject_name) {
                                  setActiveItem3("");
                                } else {
                                  setActiveItem3(item.subject_name);
                                }
                                setSubjectName(item.subject_name);
                                setModuleName("");
                                setDownload("");
                              }}
                            >
                              <div>{item.subject_name}</div>
                              {activeitem3 === item.subject_name ? (
                                <>
                                  <AiOutlineMinus style={iconstyle} />
                                </>
                              ) : (
                                <>
                                  <AiOutlinePlus style={iconstyle} />
                                </>
                              )}
                            </button>
                          </h2>
                          <div
                            id={`collapse${ind}`}
                            className={`accordion-collapse collapse ${
                              activeitem3 === item.subject_name ? "show" : ""
                            }`}
                            aria-labelledby={`heading${ind}`}
                            data-bs-parent={`#accordion${ind}`}
                          >
                            <div className="card-body d-flex flex-wrap">
                              {books &&
                                books?.map((book, ind1) => {
                                  return (
                                    <>
                                      <div
                                        key={ind1}
                                        className="module-btn mx-2 my-2 px-3 py-2 btn"
                                        onClick={() => {
                                          adsFun("pdf_link", "From-pdf-link");
                                          setPdfLink(book.pdf_link);
                                        }}
                                      >
                                        {book.ebook_title}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyMaterial1;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import qs from "qs";
import axios from "axios";

export const SubjectModules = () => {

  const [downloadname, setDownloadName] = useState();
  const [modules, setModules] = useState([]);
  const [downloadpdf, setDownloadPdf] = useState();
  const [modulename, setModuleName] = useState();
  const [active,setActive] = useState();
  useEffect(() => {
    getModules();
    window.scroll(0, 0);
  }, []);
  const location = useLocation();
  
  const getModules = async () => {
    const data = {
      institute: location.state.institute,
      course: location.state.course,
      coursetype: location.state.courseType,
      semester: location.state.semester,
      subject: location.state.subject,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_modules.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      //   console.log(response, "Calling modules");
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContent = async (moduleName) => {
    const data = {
      institute: location.state.institute,
      course: location.state.course,
      coursetype: location.state.courseType,
      semester: location.state.semester,
      subject: location.state.subject,
      module: moduleName,
    };
    setModuleName(moduleName);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_ebook_content.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      console.log(response, "Response for selected module content");
      if (response.status === 200) {
        console.log(response.data[0].url, "content of modules");
        setDownloadPdf(response.data[0].url);
        setDownloadName(response.data[0].short_name);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(downloadname, "Download file name");
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{location.state.institute}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-5">
        <div className="container h-100">
          <div className="row">
            <div className="col-md-12">
              <div className="Modules-box">
                <div className="Course-heading text-center">
                  <h3>{location.state.subject}</h3>
                  <h5 className="my-2">{location.state.semester}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-5">
        <div className="container h-100">
          <div className="row">
            <div className="col-md-12">
              <div className="Modules-box d-flex justify-content-center align-items-center">
                <div>
                  {modules &&
                    modules.map((item, ind) => {
                      return (
                        <>
                          <button
                            
                            onClick={() => {
                              getContent(item.module_name);
                              setActive(item.module_name)
                            }}
                            className={`coursetype-button mx-2 my-2 ${active === item.module_name ? "activecoursetype" : ""}`}
                          >
                            {item.module_name}
                          </button>
                        </>
                      );
                    })}
                  <div className="my-4">
                    {downloadpdf ? (
                      <a href={downloadpdf} target="_blank" >
                        <p style={{textDecoration:"underline"}}>
                        Click here To Download {modulename} {downloadname}
                        </p>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SubjectModules;

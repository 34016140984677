export const FaqData = [
    {
        id:1,
        questions:"What are the requirements ?",
        answers:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis, esse sunt veniam repellendus consequuntur dolorum ut dolore placeat eius ea minus rem nostrum consectetur optio doloribus ex illum dicta.z"
    },
    {
        id:2,
        questions:"What are the requirements ?",
        answers:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis, esse sunt veniam repellendus consequuntur dolorum ut dolore placeat eius ea minus rem nostrum consectetur optio doloribus ex illum dicta.z"
    },
    {
        id:3,
        questions:"What are the requirements ?",
        answers:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis, esse sunt veniam repellendus consequuntur dolorum ut dolore placeat eius ea minus rem nostrum consectetur optio doloribus ex illum dicta.z"
    },
    {
        id:4,
        questions:"What are the requirements ?",
        answers:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis, esse sunt veniam repellendus consequuntur dolorum ut dolore placeat eius ea minus rem nostrum consectetur optio doloribus ex illum dicta.z"
    },
    {
        id:5,
        questions:"What are the requirements ?",
        answers:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem corporis, esse sunt veniam repellendus consequuntur dolorum ut dolore placeat eius ea minus rem nostrum consectetur optio doloribus ex illum dicta.z"
    }
]



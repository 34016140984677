import React from "react";
import "./StudyMaterial.css";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import pdf1 from "../../assets/pdf/Syllabus-12.pdf";
import PdfDemo from "./PdfDemo";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { BsArrowDownShort } from "react-icons/bs";
import HeaderBannerforStudy from "./HeaderBannerforStudy";
import googleplay from "../../assets/images/google-play-badge.png";
import Playstore from "../../assets/images/Playstore.gif";
import { useRef } from "react";
import Lottie from "react-lottie";
import animations from "../../Lotties/download.json";
import ShareWith from "../ShareWith";
import animation1 from "../../Lotties/Playstore.json";
import animation2 from "../../Lotties/PlayStore2.json";

const PdfViewer = () => {
  const scrollto = useRef();
  const downloadref = useRef();
  const location = useLocation();
  const [button, setButton] = useState();
  const [active, setActive] = useState();
  const [embedurl] = useState(location.state.modulepdf);
  const [breakpoint, setBreakpoint] = useState(false);
  const [isVisible, setVisibleTure] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  // console.log("embedurl#################", embedurl);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animations,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animation1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const iconStyle = {
    height: "260px",
    width:"auto"
  };

  useEffect(() => {
    setButton("On-This");
    setActive("On-This");
    window.scroll(0, 0);
    const breakpoint1 = window.innerWidth <= 768;
    const handleResize = () => {
      setBreakpoint(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollamount = window.pageYOffset;
    if (scrollamount >= 299) {
      setVisibleTure(true);
    } else {
      setVisibleTure(false);
    }
  };
  console.log(embedurl, "For pdfviewer::::::::::");
  const downloadFunction = () => {
    downloadref.current?.click();
    setIsLoading(false);
  };

  console.log(embedurl, "Checking embed url:::::::::::::::::");

  return (
    <>
      <section className="ahsec_banner1">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-11">
              <div className="headings-header">
                <div className="top-heading">
                  <div className="d-flex">
                    <p className="text-light">
                      <span className="d-flex justify-content-center gap-1 align-items-center">
                        <i class="fa-regular fa-building"></i>
                        {location?.state?.course}
                      </span>
                    </p>
                    <p className="text-light mx-4">
                      <span className="d-flex justify-content-center gap-1 align-items-center">
                        <i aria-hidden="true" class="fas fa-feather-alt"></i>
                        {location.state.studymaterialname}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-8">
              <div className="headings-header">
                <div className="ahsec1-heding">
                  <h2 className="text-light">
                    {" "}
                    {location.state.modulepdf.ebook_title}-{" "}
                    {location?.state?.studymaterialname} |{" "}
                    {location?.state?.subject} |{location.state.semestername} -{" "}
                    {location.state.course} | {location?.state?.institute}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

        {/* <div className="col-lg-5 col-md-3 col-sm-1"></div> */}
      </section>
      {breakpoint && isVisible && (
        <HeaderBannerforStudy
          state={{
            year: location.state?.modulepdf.ebook_title,
            studymat: location?.state?.studymaterialname,
            semester: location.state.semestername,
            institute: location?.state?.institute,
            course: location?.state?.course,
          }}
        />
      )}
      <section className="pdf-section">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="pdf-box">
                <div className="pdf_1">
                  <PdfDemo file={embedurl} />
                </div>
                <div ref={scrollto} className="ads-here"></div>
                <div className="button-div">
                  <div className="outer-btn-div">
                    <button
                      className={`left-btn ${
                        active === "On-This" ? "activebtn1" : ""
                      }`}
                      onClick={() => {
                        setButton("On-This");
                        setActive("On-This");
                      }}
                    >
                      On This?
                      <div className="effect-click"></div>
                    </button>
                    <button
                      className={`right-btn ${
                        active === "Get-Everything" ? "activebtn" : ""
                      }`}
                      onClick={() => {
                        setButton("Get-Everything");
                        setActive("Get-Everything");
                        setIsLoading(false);
                      }}
                    >
                      Get Everything!!!
                      <div className="effect-click1"></div>
                    </button>
                  </div>
                </div>
                <div className="button-content">
                  {button === "On-This" && (
                    <div className="button-div2">
                      <a className="d-flex justify-content-between align-items-center gap-1">
                        {/* <button
                          className="Download-text"
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(downloadFunction, 6000);
                          }}
                        >
                          <span className="bounce">↑</span> Tap To Download
                        </button> */}
                        {/* <button
                          className="tap-to-down"
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(downloadFunction, 6000);
                          }}
                        >
                          Download
                        </button> */}
                        <button
                          class="learn-more"
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(downloadFunction, 6000);
                          }}
                        >
                          <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                          </span>
                          <span class="button-text">Tap to download</span>
                        </button>
                        <div className="">
                          {isloading ? (
                            <Lottie
                              options={defaultOptions}
                              height={100}
                              width={100}
                            />
                          ) : (
                            <div className="circle-download">
                              <BsArrowDownShort className="icon-download" />
                            </div>
                          )}
                        </div>
                      </a>
                      <a
                        className="d-none "
                        ref={downloadref}
                        href={embedurl}
                        target="_blank"
                      ></a>
                    </div>
                  )}
                  {button === "Get-Everything" && (
                    <>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.rollingnotes&pli=1"
                        target="_blank"
                      >
                        <div className="container">
                          <div className="row">
                            {/* <div className="col-md-3">
                              <div className="h-100 d-flex justify-content-center align-items-center">
                                <Lottie
                                  options={defaultOptions1}
                                  style={iconStyle}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-12">
                              <div className="h-100 d-flex justify-content-center align-items-center">
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.rollingnotes&pli=1"
                                  target="_blank"
                                >
                                 <img src={Playstore} alt="" srcset="" />
                                </a>
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                              <div className="h-100 d-flex justify-content-center align-items-center">
                                hjgh
                                <Lottie
                                  options={defaultOptions2}
                                  style={iconStyle}
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-1">
              <>
                <div
                  onClick={() => {
                    scrollto.current.scrollIntoView();
                    setButton("On-This");
                    setActive("On-This");
                    setIsLoading(false);
                  }}
                  class="eael-creative-button-wrapper"
                  style={{ cursor: "pointer" }}
                >
                  <a
                    class="eael-creative-button eael-creative-button--wave"
                    data-text="Go!"
                  >
                    <div class="creative-button-inner">
                      <span class="eael-creative-button-icon-left">
                        <i
                          aria-hidden="true"
                          class="fas fa-cloud-download-alt"
                        ></i>
                      </span>
                      <span class="cretive-button-text">Download</span>
                    </div>
                  </a>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
      <section>
        {console.log("embedurl", embedurl)}
        <ShareWith pdf={embedurl} />
      </section>
    </>
  );
};

export default PdfViewer;

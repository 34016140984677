import React,{useState} from "react";
import { FaqData } from "../../utils/FaqData";
import AccordionItems from "./AccordionItems";


const AccordionCard = () => {

     const [clicked, setClicked] = useState(null);


     const handleToggle = (index)=>{
        if (clicked === index){
            return setClicked(null)
        }
        setClicked(index)

        // console.log(setClicked(index))
     }


  return (
    <>
      <div className="accordion">
        {FaqData.map((faq, index) => {
          return <AccordionItems faq={faq} key={index} onToggle={()=>handleToggle(index)} active={clicked === index}/>;
        })}
      </div>
    </>
  );
};

export default AccordionCard;

import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Modals = ({
  show,
  close,
  institute,
  StudyMaterial,
  addval,
  InstituteAdsVal,
  StudyMaterialAdsVal,
  comesFrom,
  InstituteId
}) => {
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(5);

  const redirectedToParticularPage = () => {
    let data = {institute : institute , InstituteId : InstituteId}
    if(institute.length){
      if (comesFrom === "institute")
        navigate("/Institute", { state: data });
      else navigate("/studymaterial", { state: institute });
    }
    
  };


  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);
      close();
      redirectedToParticularPage();
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  useEffect(() => {
    if (InstituteAdsVal === "0" && comesFrom === "institute") {
      return redirectedToParticularPage();
    } else if (StudyMaterialAdsVal === "0" && comesFrom === "studyMaterial") {
      return redirectedToParticularPage();
    }
  }, []);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {StudyMaterialAdsVal === "1" && comesFrom === "studyMaterial"
        ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"
                  onClick={(e) => e.stopPropagation()}
                >
                  <header className="modal_header">
                    <h2 className="modal_header-title"> Ads Page </h2>
                    
                  </header>
                  <main className="modal_content">Ads Content</main>
                  <footer className="modal_footer">
                    <div className="model_button">
                      <button className="modal-close" onClick={() => close()}>
                        Close
                      </button>
                    </div>
                    {timeleft != null && (
                      <h3>00:{timeleft < 10 ? "0" + timeleft : timeleft}</h3>
                    )}
                  </footer>
                </div>
              </div>

              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
        : InstituteAdsVal === "1" && comesFrom === "institute"
        ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"
                  onClick={(e) => e.stopPropagation()}
                >
                  <header className="modal_header">
                    <h2 className="modal_header-title"> Ads Page </h2>
                  </header>
                  <main className="modal_content">Ads Content</main>
                  <footer className="modal_footer">
                    <div className="model_button">
                      <button className="modal-close" onClick={() => close()}>
                        Close
                      </button>
                    </div>
                    {timeleft != null && (
                      <h3>00:{timeleft < 10 ? "0" + timeleft : timeleft}</h3>
                    )}
                  </footer>
                </div>
              </div>
              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
        : null}
    </>
  );
};

export default Modals;

import axios from "axios";
import React, { useEffect, useState } from "react";

const AboutParticularInstitute = (InstituteId) => {
  // console.log("InstituteId",InstituteId)
  const [aboutParticularInstDesc , setAboutParticularInstDesc] = useState([])
  const getAboutParticulaInstitute = async()=>{
    try{
      // const response = await axios.get('http://app.rollingnotes.in/backend/get_institute_description.php')
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_APP_API_URL}/backend/get_institute_description.php`)
      console.log("response =>" , response)
      console.log("response =>:" , response?.data?.data)
      setAboutParticularInstDesc(response?.data?.data)
    }
    catch(err){
      console.log("err" , err)
    }
  }
  console.log("InstituteId about",InstituteId?.id)
  useEffect(()=>{
    getAboutParticulaInstitute()
  },[])
  return (
    <>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  {
                    aboutParticularInstDesc?.map((item)=>{
                      return(
                        <>
                       {console.log("item?.institute_id && " , item?.institute_id)}
                       {console.log("InstituteId?.id " , InstituteId?.id)}
                        {item?.institute_id && item?.institute_id == InstituteId?.id && (
                          <>
                           <h2 className="my-2">
                  {item?.title}
                  </h2>
                  <div className="content mt-5">
                  <p className="my-4">
                 {item?.description_c}
                  </p>
                </div>
                          </>
                        )}

                        
                        </>
                      )
                    })
                  }
                  
              </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutParticularInstitute;

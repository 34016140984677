import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const StudyMaterialsAdsModal = ({
  show,
  close,
  adsval,
  state,
  fornavigate,
}) => {
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(5);

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);
      close();
      if (fornavigate === "Form-pdflink") {
        navigate("/Pdfviewer", {
          state: {
            institute: state.institute,
            studymaterialname: state.studymaterialname,
            modulepdf: state.modulepdf,
            semestername: state.semestername,
            subject: state.subject,
            course: state.course,
          },
        });
      }
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  const handleOverlayClick = (e)=>{
    e.stopPropagation();
  }
  return (
    <>
      {show && adsval === "1" && (
        <>
          <div className="modalContainer">
            <div className="modal_show" onClick={(e) => e.stopPropagation()}>
              <header className="modal_header">
                <h2 className="modal_header-title"> Ads Page </h2>
                
              </header>
              <main className="modal_content">Ads Content</main>
              <footer className="modal_footer">
                <div className="model_button">
                  <button className="modal-close" onClick={() => close()}>
                    Close
                  </button>
                </div>
                {timeleft != null && (
                  <h3>00:{timeleft < 10 ? "0" + timeleft : timeleft}</h3>
                )}
              </footer>
            </div>
          </div>
          <div className="modal_overlay" onClick={handleOverlayClick} />
        </>
      )}
    </>
  );
};


import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./StudyMaterial.css";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { StudyMaterialsAdsModal } from "../../components/Modals/StudyMaterialsAdsModal";
import "../Previous/Previous.css";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

export const StudyMaterial1 = () => {
  //Hooks

  const { stateforheader } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [institutedata, setInstituteData] = useState();
  const [data, setData] = useState([]);
  const [coursedata, setCourseData] = useState();
  const [semesters, setSemesters] = useState();
  const [activeitem1, setactiveitem] = useState();
  const [activeitem2, setActiveItem2] = useState();
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [download, setDownload] = useState();
  const [modulename, setModuleName] = useState();
  const [activeitem3, setActiveItem3] = useState();

  const [SelectedInstitute, setSelectedInstitute] = useState();
  const [semestername, setSemesterName] = useState();
  const [subjectname, setSubjectName] = useState();
  const [pdfLink, setPdfLink] = useState();

  const [institutename, setInstituteName] = useState();
  const [studymaterial, setStudyMaterial] = useState();

  const [moduleForDownload, setModuleForDownload] = useState();
  const [isvisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [adsVal, setAdsVal] = useState();

  const iconstyle = {
    height: "25px",
    width: "25px",
  };

  useEffect(() => {
    setDownload("");
  }, [setBooks]);

  useEffect(() => {
    adsFun();
    getInstitute();
    window.scroll(0, 0);
    setSubjects("");
    setActiveItem2("");
    setBooks("");
    setDownload("");
  }, []);

  useEffect(() => {
    getInstitute();
    window.scroll(0, 0);
    setSubjects("");
    setActiveItem2("");
    setBooks("");
    setDownload("");
    setSemesters("");
    setCourseData("");
    setactiveitem("");
  }, [stateforheader]);

  //ads function

  const adsFun = async (comesfrom, forpdf) => {
    try {
      const response = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
      );
      if (response.status === 200) {
        response.data.studymaterial.Institute_name = 1;
        response.data.studymaterial.book = 1;

        let data = response.data.studymaterial;
        Object.keys(data).map((key) => {
          if (comesfrom == key) {
            handleModal(data[key], forpdf);
          }
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  //Functions

  const getInstitute = async () => {
    try {
      const response = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/fetch_institutes.php"
      );
      if (response.status === 200) {
        let arr = [];
        for (let institute of response.data) {
          arr.push(institute);
        }
        setInstituteData(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStudyMaterial = async (instituteselected) => {
    const institute = instituteselected.replace(/\s+/g, "_").trim();
    const modulename = location.state.replace(/\s+/g, "_");
    setSelectedInstitute(instituteselected);
    setInstituteName(instituteselected);
    setStudyMaterial(location.state);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/new/Study_Materials.php?institute=${institute}&module=${modulename}`,

        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.status === 200) {
        setData(res.data.data.semster);
        const data = res.data.data.semster;
        const filteredSemesters = Object.fromEntries(
          Object.entries(data).filter(([_, arr]) => arr.length !== 0)
        );
        let Semester = Object.keys(filteredSemesters).map((key) => {
          return key;
        });
        setSemesters(Semester);
        setCourseData(res.data.data.course);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleModal = (ads, forpdf) => {
    setModuleForDownload(forpdf);
    setShowModal(!showModal);
    setAdsVal(ads);
  };

  const getSubjects = (semester) => {
    setSemesterName(semester);
    for (const key in data) {
      if (key === semester) {
        let arr = [];
        arr.push(data[key]);
        setSubjects(arr);
      }
    }
  };

  const getBooks = (book) => {
    setSubjectName(book);
    subjects[0].map((item) => {
      if (item.subject_name === book) {
        for (let key in item) {
          setBooks(item[key]);
        }
      }
    });
  };

  //JSX
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>{location?.state}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <StudyMaterialsAdsModal
          show={showModal}
          close={handleModal}
          adsval={adsVal}
          state={
            moduleForDownload
              ? {
                  institute: institutename,
                  studymaterialname: studymaterial,
                  modulepdf: pdfLink,
                  semestername: semestername,
                  subject: subjectname,
                  course: coursedata,
                }
              : ""
          }
          fornavigate={moduleForDownload}
        />
      )}
      <div className="Institute-box py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="head-text text-center py-5">
                Select Your University Here:
              </p>
              {/* <div className="button_div"> */}
              <div className="row d-flex justify-content-around align-items-center">
                {institutedata &&
                  institutedata?.map((item, ind) => {
                    return (
                      <div className="col-md-2 px-4 py-1">
                        <div className="button_div" key={ind}>
                          <button
                            className={`btn btn-primary ${
                              activeitem1 === item.Institutes_name ||
                              location.state === activeitem1
                                ? "activeUni"
                                : ""
                            }`}
                            onClick={() => {
                              setactiveitem(item.Institutes_name);
                              getStudyMaterial(item.Institutes_name);
                              adsFun("Institute_name");
                              setActiveItem2("");
                              setSubjects("");
                              setBooks("");
                              setDownload("");
                              setActiveItem3("");
                            }}
                          >
                            <div className="button-inner">
                              {item?.Institutes_name}
                            </div>
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="Faq py-1 my-1">
        <h3 className="text-center py-5">{coursedata}</h3>
        <div className="container">
          <div className="row">
            <div className="previous_pdf previous_one tabs_button d-flex justify-content-center">
              <div className="col-md-12">
                {semesters?.length > 0 && (
                  <p className="text-center py-5">
                    Select Your Semester or Class Below:
                  </p>
                )}
                <div
                  className="nav  nav-pills "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {semesters &&
                    semesters?.map((item, ind) => {
                      return (
                        <button
                          key={ind}
                          onClick={() => {
                            getSubjects(item);
                            adsFun("semester");
                            setActiveItem2(item);
                            setBooks("");
                            setDownload("");
                            setActiveItem3("");
                          }}
                          className={`nav-link ${
                            activeitem2 === item ? "active" : ""
                          }`}
                          id={`v-pills-${ind}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#v-pills-${ind}`}
                          type="button"
                          role="tab"
                          aria-controls={`v-pills-${ind}`}
                          aria-selected={
                            activeitem2 === item ? "true" : "false"
                          }
                        >
                          <i className="fa-regular fa-folder me-3"></i> {item}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="py-4 text-center">
                {subjects[0] && (
                  <p className="text-light">
                    Please Click on Subject and Then Year to Download :{" "}
                  </p>
                )}
              </div>

              <div className="row">
                <div className="col py-4">
                  {subjects[0] &&
                    subjects[0]?.map((item, ind) => (
                      <div
                        key={ind}
                        className="accordion accordion-position"
                        id={`accordion${ind}`}
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`heading${ind}`}>
                            <button
                              className={`accordion-button study-accordion w-100 d-flex justify-content-between ${
                                activeitem3 === item.subject_name
                                  ? ""
                                  : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${ind}`}
                              aria-expanded={
                                activeitem3 === item.subject_name
                                  ? "true"
                                  : "false"
                              }
                              onClick={() => {
                                if (activeitem3 === item.subject_name) {
                                  setActiveItem3("");
                                } else {
                                  setActiveItem3(item.subject_name);
                                }
                                getBooks(item?.subject_name);
                                adsFun("subject_name");
                                // setActiveItem3(item?.subject_name);
                                setModuleName("");
                                setDownload("");
                              }}
                            >
                              <div>{item.subject_name}</div>
                              {activeitem3 === item.subject_name ? (
                                <>
                                  <AiOutlineMinus style={iconstyle} />
                                </>
                              ) : (
                                <>
                                  <AiOutlinePlus style={iconstyle} />
                                </>
                              )}
                            </button>
                          </h2>
                          <div
                            id={`collapse${ind}`}
                            className={`accordion-collapse collapse ${
                              activeitem3 === item?.subject_name ? "show" : ""
                            }`}
                            aria-labelledby={`heading${ind}`}
                            data-bs-parent={`#accordion${ind}`}
                          >
                            <div className="card-body d-flex flex-wrap">
                              {books &&
                                books?.map((book, ind1) => {
                                  return (
                                    <div
                                      key={ind1}
                                      // style={{
                                      //   cursor: "pointer",
                                      //   color: "white",
                                      //   fontSize: "14px",
                                      //   backgroundColor: "#0ea1ff",
                                      //   border: "none",
                                      //   borderRadius: "5px",
                                      // }}
                                      className="module-btn mx-2 my-2 px-3 py-2 btn "
                                      onClick={() => {
                                        setModuleName(book);
                                        setPdfLink(book.pdf_link);

                                        adsFun("pdf_link", "Form-pdflink");
                                      }}
                                    >
                                      {book?.ebook_title}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudyMaterial1;

import React from 'react';
import "./StudyMaterial.css";


const HeaderBannerforStudy = ({state}) => {


  return (
    <section className={`sticky-banner `}>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="content-box">
          <div className="ahsec2-heding w-60">
              <p className="sticky-text text-light">
                {" "}
                {state?.year}- {state?.course} |{" "}
                {state?.semester} - | {state?.institute} | {state?.studymat}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  )
}

export default HeaderBannerforStudy
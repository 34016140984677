export const HomeTabs = [
    {
        id:1,
        category:"institute",
        buttonTitle:"Dibrugarh University",
        link:"dibrugarh"
    },
    {
        id:2,
        category:"institute",
        buttonTitle:"Guwahati University",
        link:"guwahati"
    },
    {
        id:3,
        category:"institute",
        buttonTitle:"AHSEC",
        link:"ahsec"
    },
    {
        id:4,
        category:"institute",
        buttonTitle:"CBSE",
        link:"cbse"

    },
    {
        id:5,
        category:"institute",
        buttonTitle:"NIOS",
        link:"nios"
    },
    {
        id:6,
        category:"study-material",
        buttonTitle:"Previous Year Question Papers",
        link:"previous-year-questions"
    },
    {
        id:7,
        category:"study-material",
        buttonTitle:"Important Questions",
        link:"important-questions"
    },
    {
        id:8,
        category:"study-material",
        buttonTitle:"NCERT Solution",
        link:"ncert"
    },
    {
        id:9,
        category:"study-material",
        buttonTitle:"Study Notes",
        link:"study-notes"
    },
    {
        id:10,
        category:"study-material",
        buttonTitle:"Solved Question Papers",
        link:"solved-papers"
    },
    {
        id:11,
        category:"study-material",
        buttonTitle:"Long Questions",
        link:"long-question"
    }, 
    {
        id:12,
        category:"study-material",
        buttonTitle:"Short Questions",
        link:"short-question"
    }, 
    {
        id:13,
        category:"study-material",
        buttonTitle:"Syllabus",
        link:"syllabus"
    },
    {
        id:14,
        category:"study-material",
        buttonTitle:"FlowCharts",
        link:"flowchart"
    },
]
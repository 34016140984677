import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import AppContext from "../../feature/ContextForHeader/ContextForheader";

const AdsModalForInstitute = ({
  show,
  close,
  adsval,
  comesFrom,
  state,
  fornavigate,
}) => {
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(5);

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);

      close();
      if (fornavigate === "From-pdflink") {
        navigate("/pdfforinstitute", {
          state: {
            institute: state.institute,
            course: state.course,
            coursetype: state.coursetype,
            bookname: state.bookname,
            semester: state.semester,
            module: state.module,
          },
        });
      }
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  if (adsval === "0") {
    close();
  }

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {adsval === "1"
        ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"
                  onClick={(e) => e.stopPropagation()}
                >
                  <header className="modal_header">
                    <h2
                      className="modal_header-title"
                      style={{ color: "black" }}
                    >
                      Modal Title
                    </h2>
                  </header>
                  <main className="modal_content" style={{ color: "black" }}>
                    This is modal content
                  </main>
                  <footer className="modal_footer">
                    <div className="model_button">
                      <button
                        className="modal-close"
                        style={{ color: "black" }}
                        onClick={() => close()}
                      >
                        Close
                      </button>
                    </div>
                    {timeleft != null && (
                      <h3 style={{ color: "black" }}>
                        00:{timeleft < 10 ? "0" + timeleft : timeleft}
                      </h3>
                    )}
                  </footer>
                </div>
              </div>
              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
        : null}
    </>
  );
};

export default AdsModalForInstitute;

import React from "react";
import Slider from "react-slick";
import TestimonialCards from "../../../components/TestimonialCard/TestimonialCards";
import "./Testimonial.css"

export default function Testimonial() {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
     responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  return (
    <>
      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="testimonial_title text-center py-3 mt-4">
              <h5>Testimonial</h5>
              <h2>What Student Saying</h2>
            </div>
              <Slider {...settings}>
                <div>
                  <TestimonialCards />
                </div>
                <div>
                  <TestimonialCards />
                </div>
                <div>
                  <TestimonialCards />
                </div>
                <div>
                  <TestimonialCards />
                </div>
                <div>
                  <TestimonialCards />
                </div>
                <div>
                  <TestimonialCards />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
